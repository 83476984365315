require('dotenv').config()
const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY
const { createAlchemyWeb3 } = require('@alch/alchemy-web3')
const web3 = createAlchemyWeb3(alchemyKey)

const pinataSDK = require('@pinata/sdk')
// const pinata = pinataSDK(process.env.PINATA_API_KEY, process.env.PINATA_API_SECRET)
const pinata = pinataSDK('0bcb3b6e5d2ad9da278d', '539d4cab8dcfaf2add474eed73c4919bff4be6fa723e1d7a5cd88d72fc54ea74')

// Tutorial
// const contractABI = require('../contract-abi.json')
// const contractAddress = '0x6f3f635A9762B47954229Ea479b4541eAF402A6A'

const rd_contract = require('../MyNFT.json')
const contractAddress = '0xa2b1fb24cfb1cbebc28d8ec9e1aab3ac82896d96'

// export const helloWorldContract = new web3.eth.Contract(contractABI, contractAddress)

export const helloWorldContract = new web3.eth.Contract(rd_contract.abi, contractAddress)

export const loadCurrentMessage = async () => {
  // const message = await helloWorldContract.methods.message().call()
  // return message
}

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: 'eth_requestAccounts',
      })
      const obj = {
        status:
          '👆🏽 Pretending to talk to a “Rubber Duck” will solve all your problems. Do it here, and MINT it on the BLOCKCHAIN (testnet)!',
        address: addressArray[0],
      }
      return obj
    } catch (err) {
      return {
        address: '',
        status: '😥 ' + err.message,
      }
    }
  } else {
    return {
      address: '',
      status: (
        <span>
          <p>
            {' '}
            🦊{' '}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your browser.
            </a>
          </p>
        </span>
      ),
    }
  }
}

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: 'eth_accounts',
      })
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status:
            '👆🏽 Pretending to talk to a “Rubber Duck” will solve all your problems. Do it here, and MINT it on the BLOCKCHAIN (testnet)!',
        }
      } else {
        return {
          address: '',
          status: '🦊 Connect to Metamask using the top right button.',
        }
      }
    } catch (err) {
      return {
        address: '',
        status: '😥 ' + err.message,
      }
    }
  } else {
    return {
      address: '',
      status: (
        <span>
          <p>
            {' '}
            🦊{' '}
            <a target="_blank" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your browser.
            </a>
          </p>
        </span>
      ),
    }
  }
}

export const sendFileToPinata = async (address, message) => {
  const body = {
    description: message,
    image: 'ipfs://QmXce3Gg3ShHBjGbg4YtmkLrHThyqWpsp7oT2rYBmwexgP',
    name: 'Rubber dApp NFT',
  }
  const options = {
    pinataMetadata: {
      name: 'nft-metadata.json',
    },
  }

  try {
    const result = await pinata.pinJSONToIPFS(body, options)
    const status = await updateMessage(address, result.IpfsHash)
    //handle results here
    console.log(result.IpfsHash)
    // IpfsHash
    console.log('sucesso')
    return status
  } catch (err) {
    //handle error here
    console.log(err)
    console.log('erro')
  }

  return { status: '' }
}

export const updateMessage = async (address, message) => {
  if (!window.ethereum || address === null) {
    return {
      status: '💡 Connect your Metamask wallet to update the message on the blockchain.',
    }
  }
  if (message.trim() === '') {
    return {
      status: '❌ Your message cannot be an empty string.',
    }
  }
  //set up transaction parameters
  const transactionParameters = {
    to: contractAddress, // Required except during contract publications.
    from: address, // must match user's active address.
    data: helloWorldContract.methods.mintNFT(address, `ipfs://${message}`).encodeABI(),
  }
  //sign the transaction
  try {
    const txHash = await window.ethereum.request({
      method: 'eth_sendTransaction',
      params: [transactionParameters],
    })
    return {
      status: (
        <span>
          ✅{' '}
          <a target="_blank" href={`https://ropsten.etherscan.io/tx/${txHash}`}>
            View the status of your transaction on Etherscan!
          </a>
          <br />
          ℹ️ Once the transaction is verified by the network, the message will be updated automatically.
        </span>
      ),
    }
  } catch (error) {
    return {
      status: '😥 ' + error.message,
    }
  }
}

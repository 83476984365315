import React from 'react'
import { useEffect, useState } from 'react'
import {
  helloWorldContract,
  connectWallet,
  updateMessage,
  loadCurrentMessage,
  getCurrentWalletConnected,
  sendFileToPinata,
} from './util/interact.js'

import alchemylogo from './Screen Shot 2022-04-08 at 23.35.55.png'

const HelloWorld = () => {
  //state variables
  const [walletAddress, setWallet] = useState('')
  const [status, setStatus] = useState('')
  const [message, setMessage] = useState('No connection to the network.') //default message
  const [newMessage, setNewMessage] = useState('')

  useEffect(() => {
    async function fetchMessage() {
      const message = await loadCurrentMessage()
      setMessage(message)
    }
    fetchMessage()
    // addSmartContractListener()

    async function fetchWallet() {
      const { address, status } = await getCurrentWalletConnected()
      setWallet(address)
      setStatus(status)
    }
    fetchWallet()
    addWalletListener()
  }, [])

  // function addSmartContractListener() {
  //   helloWorldContract.events.UpdatedMessages({}, (error, data) => {
  //     if (error) {
  //       setStatus('😥 ' + error.message)
  //     } else {
  //       setMessage(data.returnValues[1])
  //       setNewMessage('')
  //       setStatus('🎉 Your message has been updated!')
  //     }
  //   })
  // }

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on('accountsChanged', (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0])
          setStatus('👆🏽 Describe your problem in search of epiphany')
        } else {
          setWallet('')
          setStatus('🦊 Connect to Metamask using the top right button.')
        }
      })
    } else {
      setStatus(
        <p>
          {' '}
          🦊{' '}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your browser.
          </a>
        </p>
      )
    }
  }

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet()
    setStatus(walletResponse.status)
    setWallet(walletResponse.address)
  }

  const onUpdatePressed = async () => {
    // const { status } = await updateMessage(walletAddress, newMessage)
    // setStatus(status)
    const { status } = await sendFileToPinata(walletAddress, newMessage)
    setStatus(status)
  }

  //the UI of our component
  return (
    <div id="container">
      <img id="logo" src={alchemylogo}></img>
      <button id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          'Connected: ' + String(walletAddress).substring(0, 6) + '...' + String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        )}
      </button>

      <h2 style={{ paddingTop: '18px' }}>Write your problem:</h2>

      <div>
        <input type="text" placeholder="Problem" onChange={(e) => setNewMessage(e.target.value)} value={newMessage} />
        <p id="status">{status}</p>

        <button id="publish" onClick={onUpdatePressed}>
          MINT YOUR PROBLEM
        </button>
      </div>
    </div>
  )
}

export default HelloWorld
